import React from 'react';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Image from '../components/atoms/Image';
import MarkdownConverter from '../components/atoms/MarkdownConverter';
import Border from '../assets/uf_border-1.inline.svg';

const Holder = styled.div`
  max-width: 60rem;
  .gatsby-image-wrapper {
    margin-bottom: 2rem;
  }
`;

const Post = styled.div`
  border-top: 2px solid;
  padding-top: 2rem;
`;

const PostText = styled.div`
  margin-bottom: 4rem;
`;

function UpdatesPage( { data } ) {

  return (
    <>
      <Seo title="Updates" description="Find out about the development of new products, market stalls and other valuable knowledge about Unicorns Forever"/>
      <h1>Updates</h1>
      <Holder>
        {data.allMarkdownRemark.nodes.map( update =>
          <Post key={update.frontmatter.id}>
            {update.frontmatter.hero && <Image imgName={update.frontmatter.hero}/>}
            <PostText>
              <h2 className="h1">{update.frontmatter.title}</h2>
                <small><em>{update.frontmatter.date}</em></small>
              <MarkdownConverter content={update.html}/>
            </PostText>
            {update.frontmatter.images && update.frontmatter.images.map( image =>
              <Image key={image} imgName={image}/>
            )}
          </Post>
        )}
      </Holder>
    </>
  )
}

export default UpdatesPage;

export const updatexPageQuery = graphql`
    query UpdateQuery {
        allMarkdownRemark(
            sort: {fields: frontmatter___date, order: DESC}
            filter: {frontmatter: {postType: {eq: "update"}}}
        ) {
            nodes {
                frontmatter {
                    title
                    id
                    hero
                    images
                    date(formatString: "do MMM, YYYY")
                }
                html
            }
        }
    }
`;

